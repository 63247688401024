import React from 'react'; 
import SiteLayout from '@layouts/site-layout';

export default () => (
    <SiteLayout>
        <section className="section top_margin_comn">
            <div className="container">
                <div className="content thanks_sec">
                    <h1>Thank you!</h1>
                    <p> Your request has been send successfully.</p>
                </div>
            </div>
        </section>
    </SiteLayout>
)
